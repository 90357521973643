<template>
  <!-- container -->
  <div id="container">
    <!-- 본문 -->
    <div id="contents">
      <!-- 서브 배너 -->
      <section class="subTopArea">
        <h2 v-html="$t('views.news.banner1')"></h2>
        <small v-html="$t('views.news.banner2')"></small>
      </section>
      <!-- //서브 배너 -->

      <section class="sub-newsWrap">
        <div class="inner-fixed">
          <ul class="newsWrap clear">
            <li
              v-for="(news, index) in newsList"
              :key="index"
            >
              <div class="newsBox" @click="handleLink('newsView', {'query': {'id': news.id}})">
                <div class="imgBox"><img :src="news.thumbnailImage" alt=""></div>
                <div class="imgInfo">
                  <title>{{news.thumbnailTitle}}</title>
                  <small>{{news.thumbnailInfo}}</small>
                  <a @click="handleLink('newsView', {'query': {'id': news.id}})"><span>{{ $t('views.news.details') }}</span> <i class="icon-arrow">></i></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="btn-cen" v-if="totalCount > maxCount && newsList.length < totalCount"><a class="button-go-line" @click="getNewsList('open')">{{newsList.length}}/{{totalCount}} {{ $t('views.news.more') }}</a></div>
        <div class="btn-cen" v-if="totalCount > maxCount && newsList.length > 0 && newsList.length >= totalCount"><a class="button-go-line" @click="getNewsList('close')">{{ $t('common.close') }}</a></div>
      </section>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import { handleLink } from '@/assets/js/util.js'
import instance from '@/assets/js/api.js'

export default {
  name: 'news',
  data() {
    return {
      maxCount: 9,
      baseUrl: '',
      newsList: [],
      totalCount: 0,
      currentPage: 0,
    }
  },
  methods: {
    getNewsList (flag) {
      if (flag == 'close') {
        this.currentPage = 0
        this.totalCount = 0
        this.newsList = []
      }
      const url = '/news/list'
      const method = 'get'
      this.currentPage += 1
      const query = { maxCount: this.maxCount, page: this.currentPage }
      let data = instance({
        url,
        method,
        params: query
      })
      data.then(responseData => {
        const {
          result,
          response
        } = responseData

        if (result) {
          const {
            list,
            totalCount,
          } = response

          this.newsList = this.newsList.concat(list)
          this.totalCount = totalCount
        }
      })
      .catch(error => {
      })
    },
    replaceByImageDefault (e) {
      e.target.src = '@/assets/images/image-news-3@3x.png'
    },
    handleLink (path, query) { handleLink(this.$router, path, query) }
  },
  created: function () {
    this.baseUrl = process.env.VUE_APP_ACRYL_BASE_URL
    this.getNewsList()
  },
}
</script>

<style>

</style>
